import { useState } from 'react';
import './App.css';

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const majorKeys = ['C', 'F', 'Bb', 'Eb', 'Ab', 'Db', 'F#', 'B', 'E', 'A', 'D', 'G'];
const minorKeys = ['Cm', 'Fm', 'Bbm', 'Ebm', 'G#m', 'C#m', 'F#m', 'Bm', 'Em', 'Am', 'Dm', 'Gm'];
const allKeys = majorKeys.concat(minorKeys);
const shuffledKeys = shuffle(allKeys)


function App() {
  const [keys, setKeys] = useState(shuffledKeys);
  const currentKey = keys[0];

  const rotateKeys = () => setKeys([...keys.slice(1), keys[0]])
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-value">{currentKey}</div>
        <button className="App-button" onClick={rotateKeys}>Next</button>
        <div className="App-spacer"/>
      </header>
    </div>
  );
}

export default App;
